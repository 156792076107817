import { useId, useRef } from "react";
import Image from "next/image";
import {
  AlignItems,
  Box,
  Breakpoint,
  ColorPreset,
  Dropdown,
  DropdownTriggerProps,
  FontWeight,
  Glyph,
  Icon,
  PlainButton,
  Space,
  Text,
  useTheme,
} from "@gocardless/flux-react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";

import { UpcomingFeatureDialog } from "../dialogs/UpcomingFeatureDialog/UpcomingFeatureDialog";

import { marqueeStyle } from "./OrganisationSwitcher.style";
import { OrganisationSwitcherMenu } from "./OrganisationSwitcherMenu";

import { useOrgSwitcherData } from "src/libraries/organisation/multi-accounts/hooks";
import useToggle from "src/hooks/useToggle";
import { useIsBreakpoint } from "src/hooks/useIsBreakpoint";
import gocardlessLogo from "src/assets/svg/gc-logo-square.svg";

interface OrganisationSwitcherProps {
  // Currently used for Storybook only
  useCustomHoverStyle?: boolean;
}

interface TriggerButtonProps extends DropdownTriggerProps {
  label: string | undefined;
}

const TriggerButton: React.FC<TriggerButtonProps> = ({
  label = "",
  onClick,
  ...triggerProps
}) => {
  const { theme } = useTheme();

  const isBelowXlBreakpoint = useIsBreakpoint({ max: Breakpoint.Lg });

  const marqueeTextRef = useRef<HTMLSpanElement>(null);

  const overflowWidth = marqueeTextRef.current?.clientWidth
    ? marqueeTextRef.current.scrollWidth - marqueeTextRef.current.offsetWidth
    : 0;

  return (
    <PlainButton
      {...triggerProps}
      onClick={onClick}
      css={{
        ":hover": {
          "svg, span": {
            color: theme.color(
              isBelowXlBreakpoint
                ? ColorPreset.TextOnLight_02
                : ColorPreset.TextOnDark_02
            ),
          },
        },
      }}
    >
      <Box layout="flex" alignItems={AlignItems.Center}>
        <Image
          alt={i18n._(t({ message: "GoCardless logo" }))}
          src={gocardlessLogo}
          width={32}
          height={32}
        />
        <Space layout="inline" h={0.75} />
        <Box css={marqueeStyle(theme, { overflowWidth })}>
          <Text
            ref={marqueeTextRef}
            color={[
              ColorPreset.TextOnLight_01,
              null,
              null,
              ColorPreset.TextOnDark_01,
            ]}
            layout="block"
            size={3}
            weight={FontWeight.SemiBold}
          >
            {label}
          </Text>
        </Box>
        <Space layout="inline" h={0.5} />
        <Icon
          color={[
            ColorPreset.TextOnLight_01,
            null,
            null,
            ColorPreset.TextOnDark_01,
          ]}
          name={Glyph.ChevronDown}
          size="12px"
        />
      </Box>
    </PlainButton>
  );
};

export const OrganisationSwitcher: React.FC<OrganisationSwitcherProps> = ({
  useCustomHoverStyle = false,
}) => {
  const { loggedInOrganisation, organisations } = useOrgSwitcherData();

  const {
    isOn: isUpcomingFeatureDialogOpen,
    on: openUpcomingFeatureDialog,
    off: closeUpcomingFeatureDialog,
  } = useToggle();

  const dropdownId = useId();

  return (
    <>
      <Dropdown
        // Prevents dropdown from closing when interacting with UpcomingFeatureDialog
        controls={dropdownId}
        forceOpen={isUpcomingFeatureDialogOpen}
        appendToBody
        gutterH={1.5}
        gutterV={1.5}
        maxWidth="max-content"
        trigger={(triggerProps) => (
          <TriggerButton
            {...triggerProps}
            label={loggedInOrganisation?.nickname ?? loggedInOrganisation?.name}
          />
        )}
      >
        <OrganisationSwitcherMenu
          currentOrg={loggedInOrganisation}
          organisations={organisations}
          useCustomHoverStyle={useCustomHoverStyle}
          onAddAccountClick={openUpcomingFeatureDialog}
        />
      </Dropdown>
      <UpcomingFeatureDialog
        open={isUpcomingFeatureDialogOpen}
        onClose={closeUpcomingFeatureDialog}
      />
    </>
  );
};

import { OrganisationResource } from "@gocardless/api/dashboard/types";

import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";

export const usePMOImprovements = (
  organisation: OrganisationResource | undefined
) => {
  const { isVariationOn: isPartnerMerchantOnboardingImprovementsEnabled } =
    useOptimizelyVariation({
      flag: OptimizelyFlag.VERIFICATIONS_PARTNER_MERCHANT_ONBOARDING_IMPROVEMENTS,
    });

  return (
    !!organisation?.is_partner_merchant &&
    isPartnerMerchantOnboardingImprovementsEnabled
  );
};
